import { API, graphqlOperation } from 'aws-amplify'
import { listOrganizations, listRoegistries, listRoegistryEntries, listRoegistryEntryPledges } from '../graphql/queries'
import { createRoegistry } from '../graphql/mutations'
import { OrganizationType } from '../models/index'
import { v4 as uuidv4 } from 'uuid';
import _ from "lodash";

const limit = 1000;

export const ORG_TYPE_TO_DESCRIPTION = {
    [OrganizationType['GENERAL_ACCESS']]: 'contribute to funds that provide direct financial support to people seeking abortion.',
    [OrganizationType['REPRODUCTIVE_JUSTICE']]: 'donate to Black and/or Indigeneous-led organizations working to advance reproductive justice.',
    [OrganizationType['INDEPENDENT']]: 'support small, independent abortion clinics to help keep their doors open.',
    [OrganizationType['SELF_MANAGED']]: 'support organizations providing information about and access to abortion pills and safe self-managed abortion.',
    [OrganizationType['ADVOCACY']]: 'donate to legal organizations working to protect people from being criminalized for their abortions or miscarriages.',
    [OrganizationType['CANDIDATE']]: 'support pro-choice candidates.',
}

export async function getRoegistryByUUID(roegistryUUID, edit = true) {
    try {

        let filter = { edit_uuid: { eq:  roegistryUUID}, is_deleted: { eq: false } };
        if (!edit) {
            filter = { view_uuid: { eq:  roegistryUUID}, is_deleted: { eq: false } };
        }

        const roegistries = await listPaginate(listRoegistries, filter);
        if (roegistries.length === 0) {
            return null;
                } else {
            return roegistries[0];
        }
    } catch (err) { console.log('error getting roegsitry by UUID', err)}
}

export async function getRoegistryEntriesByRoegistryId(roegistryId) {
    try {
        const filter = { roegistry_id : { eq: roegistryId}, is_deleted: { eq: false } };
        return await listPaginate(listRoegistryEntries, filter);
    } catch (err) { console.log('error fetching roegsitry entries by roegistry id', err)}
}

export async function getAllOrgs() {
    try {
        const filter = {is_deleted: { eq: false } };
        return await listPaginate(listOrganizations, filter);
    } catch (err) { console.log('error getting orgs', err) }
}

export async function getRoegistryEntryPledgesByRoegistryId(roegistryId) {
    try {
        const filter = { roegistry_id : { eq: roegistryId}, is_deleted: { eq: false } };
        return await listPaginate(listRoegistryEntryPledges, filter);
    } catch (err) { console.log('error fetching roegsitry entries pledges by roegistry id', err)}
}

async function listPaginate(query, filter = {}) {
    let res = [];
    try {
        let resp = await API.graphql(graphqlOperation(query, { filter, limit }));
        const key = _.keys(resp.data)[0];
        let items = resp.data[key].items;
        let nextToken = resp.data[key].nextToken;
        res = res.concat(items);

        while (nextToken) {
            resp = await API.graphql(graphqlOperation(query, { filter, limit, nextToken }));
            items = resp.data[key].items;
            nextToken = resp.data[key].nextToken;
            res = res.concat(items);
        }
    } catch (err) { console.log('error running query', query, filter) }

    return res;
}

export async function handleCreateRoegistry(navigate) {
    try {
        const data = {
            edit_uuid: uuidv4(),
            view_uuid: uuidv4(),
            title: '',
            is_deleted: false,
        }
        const resp = await API.graphql(graphqlOperation(createRoegistry, {input: data }));
        const roegistry = resp.data.createRoegistry;
        navigate(`/edit/roegistry/${roegistry.edit_uuid}`);
        navigate(0);
    } catch (err) { console.log('error creating roegistry', err) }
}

export function numericOnly(e) {
    if (e.key === '-' || e.key === '.' || e.key === '+') {
        e.preventDefault()
    }
}

export function openInNewTab(link) {
    window.open(link, '_blank', 'noopener,noreferrer')
}