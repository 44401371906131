import { createTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';


export const roegistryTheme = createTheme({
    palette: {
        primary: {
            main: grey[100],
          },
          secondary: {
            main: grey[900],
          },
    }
});


