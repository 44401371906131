
import React from 'react'
import { Amplify } from 'aws-amplify'
import { useNavigate } from "react-router-dom";
import { handleCreateRoegistry } from "../utils/utils.js"
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import awsExports from "../aws-exports";

Amplify.configure(awsExports);

function Home() {
    const navigate = useNavigate();

    const cardData = [
        ['Abortion access funds.', 'home_card_1.jpg'],
        ['Black and indigenous-led organizations advancing reproductive justice.', 'home_card_2.jpg'],
        ['Legal groups working to protect people from being criminalized for abortion and miscarriage.', 'home_card_3.jpg'],
        ['Small, independent abortion clinics.', 'home_card_4.jpg'],
        ['Organizations providing information about and access to safe self-managed abortion.', 'home_card_5.jpg'],
        ['Pro-choice candidates.', 'home_card_6.jpg'],
    ];

    return (
        <Stack>
            <Paper variant="string">
                <Card>
                    <CardMedia component="img" image={`/static/images/banner.jpg`}/>
                </Card>
                {/* <Typography variant="h4" align="center" fontWeight="bold">
                    “With sorrow—for this Court, but more, for the many millions of American women who have today lost a fundamental constitutional protection—we dissent.”
                </Typography> */}
            </Paper>
            <Paper variant="string" sx={ {backgroundColor: 'secondary.main', height: 80, pt: 4, pb: 8, mb:2.5} }>
                <Stack>
                    <Typography sx={ { color: 'white'} } variant="h5" align="center" gutterBottom>
                        Get your own Roegistry now!
                    </Typography>
                    <Typography sx={ { color: 'grey.300'} } variant="subtitle2" align="center" gutterBottom>
                        Click below to get started
                    </Typography>
                    <Box textAlign="center">
                        <Button variant="outlined" color="primary" onClick={() => handleCreateRoegistry(navigate)}>CREATE A ROEGISTRY</Button>
                    </Box>
                </Stack>
            </Paper>
            <Paper variant="string" sx={ {mx: "10%", my: 2.5}}>
                <Grid container rowSpacing={3} columnSpacing={10}>
                    <Grid item xs={12}>
                        <Typography align="center" sx={{fontSize:24}}>
                            WHAT IT'S ABOUT
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography align="center" fontWeight="bold" gutterBottom>
                            What's a Roegistry?
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography align="justify">
                            For starters, it's not a bridal registry or a baby registry. <Typography display={"inline"} sx={{fontStyle: 'italic'}}>Anyone</Typography> can create a Roegistry (pronounced ROE-jis-tree) to accompany any type of celebration or milestone or none at all. A Roegistry celebrates the right to choose–and the life you have because of it–by providing direct financial support to organizations of your choice (because, choice) that are doing critical work in the post-<Typography display={"inline"} sx={{fontStyle: 'italic'}}>Roe</Typography> era. So, if you don't need a blender or a bassinet, Roegister now.
                        </Typography>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <Typography align="center" fontWeight="bold" gutterBottom>
                            Why Roegister?
                        </Typography>
                        <Typography align="justify">
                            On June 24, 2022, the U.S. Supreme Court overturned <Typography display={"inline"} sx={{fontStyle: 'italic'}}>Roe v. Wade</Typography>, which established the constitutional right to abortion. The Court's recent decision allows total bans and strict regulations, which have already gone into effect in several states. By creating a Roegistry, you can help raise funds needed to provide assistance and access to those who need it now. 
                        </Typography>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <Typography align="center" fontWeight="bold">
                            Who can Roegister?
                        </Typography>
                        <Typography align="justify">
                            Anyone can Roegister. Do you want to do something and take action? Roegister. Planning a party and want your guests to donate? Roegister. Do you care about access to contraception, fertility treatment, and miscarriage care? Roegister. Are you concerned for your friends and family back in your home state that’s criminalizing abortion? Roegister.
                        </Typography>
                    </Grid>
                    {/* <Grid item xs={6}>
                    </Grid>
                    <Grid item xs={6}>
                    </Grid> */}
                </Grid>
            </Paper>
            <Paper variant="string" sx={ {backgroundColor: 'secondary.main', px: "10%", my: 2.5, py: 4} }>
                <Grid container rowSpacing={3} columnSpacing={10} align="center" justify="center">
                    <Grid item xs={12}>
                        <Typography sx={ { color: 'grey.300', fontSize: 24} } align="center">
                            WHO YOU CAN SUPPORT ON ROEGISTRY
                        </Typography>
                    </Grid>
                    {cardData.map(([text, img]) => (
                        <Grid item sm={12} md={6} lg={4}>
                            <Card 
                            elevation={0}
                            sx={{backgroundColor: 'secondary.main'}}>
                                <CardMedia component="img" image={`/static/images/${img}`} sx={{height: 300, width: 300, borderRadius: '50%'}}/>
                                <CardContent sx={ { bgcolor: 'secondary.main' } }>
                                    <Typography sx={ { color: 'white' } } align="center">
                                        {text}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Paper>
            <Paper variant="string" sx={{mt: 3.5, mb: 8, mx: "10%"}}>
                <Stack justifyContent="center" alignItems="center">
                    <Typography align="center" sx={{fontSize: 24}}>
                        HOW IT WORKS
                    </Typography>
                    <Typography align="justify" gutterBottom sx={{my:3}}>
                        It works like a registry–except we replace material gifts with organizations in need of donations. To Roegister, go to “Create a Roegistry” where you will be guided through three quick, easy steps: 
                    </Typography>
                    <Box>
                        <Typography align="justify" gutterBottom>
                            1. Choose whether and what to name your Roegistry.
                        </Typography>
                        <Typography align="justify" gutterBottom>
                            2. Pick which organizations to include on your Roegistry with the option to set goal amounts.
                        </Typography>
                        <Typography align="justify" gutterBottom>
                            3. Share with others. Your friends and family will be able to check out your Roegistry and select which organizations they’d like to donate to.
                        </Typography>
                    </Box>
                </Stack>
            </Paper>
            <Paper variant="string" sx={ {backgroundColor: 'secondary.main', height: 80, py:8} }>
                <Typography align="center" variant="h5" sx={ { color: 'white'} }>
                    #roegistered
                </Typography>
            </Paper>
        </Stack>
    );
}

export default Home
