// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const OrganizationType = {
  "GENERAL_ACCESS": "GENERAL_ACCESS",
  "REPRODUCTIVE_JUSTICE": "REPRODUCTIVE_JUSTICE",
  "INDEPENDENT": "INDEPENDENT",
  "SELF_MANAGED": "SELF_MANAGED",
  "ADVOCACY": "ADVOCACY",
  "CANDIDATE": "CANDIDATE"
};

const { RoegistryEntryPledge, RoegistryEntry, Organization, Roegistry } = initSchema(schema);

export {
  RoegistryEntryPledge,
  RoegistryEntry,
  Organization,
  Roegistry,
  OrganizationType
};