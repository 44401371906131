import React from 'react'
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper'
import Container from '@mui/material/Container'


function TermsOfService() {
    const paragraphs = [
"These terms of service (“Terms of Service” or “terms”) outline the rules and conditions for the use of www.roegistry.com (“the site” or “Roegistry”). Please read these Terms of Service carefully before using Roegistry. By using the site, you agree to abide by these Terms of Service. Do not continue to use the site if you do not agree to all of the Terms of Service.",
"Definitions. The following terminology applies to these Terms and Service, including the Privacy Policy, Code of Conduct, and Disclaimer, and all Agreements contained herein: \"You\" and \"Your\" refers to you, the person accessing the site and compliant to Roegistry’s terms. \"Ourselves\", \"We\", \"Our\" and \"Us\", refers to Roegistry. \"Party\", \"Parties\", or \"Us\", refers to both you and ourselves. \“Roegistry page\” refers to a webpage contained within Roegistry.com that is generated by Roegistry and assigned to you or another particular user. Any use of the above terminology or other words defined herein in the singular, plural, capitalization, and he, she or they, are taken as interchangeable and therefore as referring to the same.",
"Eligibility. The site is for everyone 18 years or older, who reside in the United States, regardless of race, sex, gender identity, sexual orientation, ethnicity, religion, disability, immigration status, martial status, pregnancy status, or identification with any other protected class. By using this site, you represent and warrant that you are at least 18 years of age and a resident of the United States. If you are under the age of 18 or are not a resident of the United States, you may not use Roegistry.com. You are solely responsible for ensuring that these Terms of Service are in compliance with all laws, rules and regulations applicable to you, and the right to access the site is revoked where these Terms of Service or use of the site is prohibited or to the extent offering or provision of the site conflicts with any applicable law, rule, or regulation.",
"Roegistry Page. You may use the site without signing up for an account. When you create a Roegistry page, you will be provided a uniform resource locator (URL) (“your link”) that you must use to create and edit your Roegistry page. You must save your link in order to later create or edit your Roegistry page. You will be provided a second URL (“shareable link”) that you must use to share your Roegistry page with others. You are solely responsible for the activity that occurs on your Roegistry page and for keeping your link and your shareable link secure. In the event you delete your Roegistry page, your link and the shareable link will no longer be accessible to you or other users of the site. ",
"Third Parties. When you use the site, Roegistry’s role is to help connect users with third party organizations. Please note that Roegistry is not affiliated with the third party organizations featured within the site and we make no representations, warranties, or guarantees with respect to them. In addition, Roegistry contains links to other websites or applications (“apps”) that are not maintained by us. We encourage you to be aware when you leave the site and to read the terms and conditions and privacy policies of each and every external website and app that you visit. We are not responsible for the practices or the content of such other websites and apps.",
"User Generated Content. Limited parts of the site offer an opportunity for you to personalize your Roegistry page and to personalize a contribution you make to another user’s Roegistry page. Together, we refer to these personalizations and any other information submitted by you for inclusion on, or posted by you to, a Roegistry page as “user generated content.” By submitting or posting user generated content, you grant Roegistry the irrevocable, perpetual, worldwide right to reproduce, display, perform, distribute, adapt, and promote this user generated content in any medium. Once you submit or post user generated content, Roegistry does not need to give you any further right to inspect or approve uses of such user generated content or to compensate you for any such uses. Your use of the site and the user generated content submitted or posted by you are governed by the Code of Conduct. You are solely responsible for anything you may submit or post and the consequences of submitting or posting anything. Roegistry is not liable for user generated content or for any liability, damages, or expenses caused or suffered as a result of any use of, posting of, or appearance of user generated content on this website.",
"Roegistry is not obligated to filter, edit, publish or review user generated content. However, Roegistry reserves the right to monitor user generated content and to block, remove, censor, or take any other reasonable action, at any point, with respect to any user generated content or Roegistry page that violates these Terms of Service or as reasonably necessary to protect our ourselves and our users.",
"Code of Conduct. By using the site, you agree that you will not engage in, or use the site in a manner that constitutes, any of the following:",
"-Hate speech, hateful conduct, or any displays of bigotry.",
"-Harassment, threats towards others, or abuse of others.",
"-Illegal conduct, including but not limited to stalking, impersonation, defamation, patent infringement, trademark infringement, or fraud",
"-Vulgarity or profanity.",
"-The sharing or dissemination of personal identification information belonging to yourself or others.",
"-Any conduct intended to disrupt, damage, limit, or interfere with the proper function of any software, hardware, or telecommunications equipment or to damage or obtain unauthorized access to any system, data, Roegistry pages, or other information stored by Roegistry.",
"If you violate these terms, including the Code of Conduct, we may take any action reasonably necessary, including but not limited to blocking, removing, or censoring your Roegistry page, suspending your access to or use of a Roegistry page, and, when appropriate, reporting to law enforcement or other relevant authority.",
"Privacy Policy. We do not ask for your personal identification information. Because Roegistry does not utilize user accounts, we do not request personal identification information pertaining to individual user accounts. However, Roegistry collects and stores certain limited information to, for instance, keep track of Roegistry pages. We store your link and the data contained therein, shareable links and the data contained therein, and user generated content you submit or post to a Roegistry page. We may also track and store certain usage information, such as dates and times of usage of the site and your IP address. While we are not required to do so, we reserve the right to track and store usage information.",
"Do not use personal identification information in any user generated content, including but not limited to using your full name in your Roegistry page name. In the event you submit or post user generated content that constitutes personal identification information (during the process of personalizing your Roegistry page or at any other time), that data will be treated as user generated content.",
"In the event a Roegistry page is deleted or removed, by you or us, we may continue to store your link, shareable links, and user generated content. However, we are not obligated to do so and you acknowledge that all information associated with such Roegistry page may be permanently deleted or otherwise unavailable to you.",
"We reserve the right to preserve and disclose any information as we reasonably believe is necessary to (i) satisfy any applicable law, regulation, legal process or governmental request; (ii) enforce these terms, including investigation of potential violations hereof; (iii) detect, prevent, or otherwise address fraud, security, or technical issues; (iv) respond to users requesting support; or (v) protect the rights, property or safety of us, our users and the public.",
"Cookies. We do not employ the use of cookies.",
"Disclaimer. We do not ensure that the information on the site is correct; we do not warrant its completeness or accuracy; nor do we promise to ensure that the site remains available or that the material on the site is kept up to date. Roegistry makes no promise or guarantee as to any results of use. To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to the site and the use of the site.",
"Indemnification. You agree to indemnify, defend, and hold harmless Roegistry and its representatives from and against any and all claims, demands, liabilities, costs or expenses, including attorney’s fees and costs, arising from, or related to (i) your use of the site, (ii) any breach by you of any of these terms, and (iii) any violation of applicable law.",
"Updates. These Terms of Service may be updated from time to time, without prior notice, and updates will govern your use of the site. Be sure to check back here for any changes. Your continued access of the site after such changes constitutes your acceptance of those changes.",
    ];

    return (
        <Container sx={{py:5}}>
            <Paper variant="string">
                <Typography align="Center" variant="h4">
                    Terms of Service
                </Typography>
                {paragraphs.map((paragraph) => (
                    <Typography sx={{my: 5}}>
                        {paragraph}
                    </Typography>
                ))}
            </Paper>
        </Container>
    );
}

export default TermsOfService;