/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getRoegistryEntryPledge = /* GraphQL */ `
  query GetRoegistryEntryPledge($id: ID!) {
    getRoegistryEntryPledge(id: $id) {
      id
      pledge_amount
      roegistry_entry_id
      roegistry_id
      is_deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listRoegistryEntryPledges = /* GraphQL */ `
  query ListRoegistryEntryPledges(
    $filter: ModelRoegistryEntryPledgeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoegistryEntryPledges(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        pledge_amount
        roegistry_entry_id
        roegistry_id
        is_deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncRoegistryEntryPledges = /* GraphQL */ `
  query SyncRoegistryEntryPledges(
    $filter: ModelRoegistryEntryPledgeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRoegistryEntryPledges(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        pledge_amount
        roegistry_entry_id
        roegistry_id
        is_deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getRoegistryEntry = /* GraphQL */ `
  query GetRoegistryEntry($id: ID!) {
    getRoegistryEntry(id: $id) {
      id
      roegistry_id
      organization_id
      goal
      is_deleted
      RoegistryEntryPledges {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listRoegistryEntries = /* GraphQL */ `
  query ListRoegistryEntries(
    $filter: ModelRoegistryEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoegistryEntries(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        roegistry_id
        organization_id
        goal
        is_deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncRoegistryEntries = /* GraphQL */ `
  query SyncRoegistryEntries(
    $filter: ModelRoegistryEntryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRoegistryEntries(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        roegistry_id
        organization_id
        goal
        is_deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      type
      name
      description
      link
      OrganizationToRoegistryEntry {
        nextToken
        startedAt
      }
      is_deleted
      donateLink
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        name
        description
        link
        is_deleted
        donateLink
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncOrganizations = /* GraphQL */ `
  query SyncOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOrganizations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        type
        name
        description
        link
        is_deleted
        donateLink
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getRoegistry = /* GraphQL */ `
  query GetRoegistry($id: ID!) {
    getRoegistry(id: $id) {
      id
      edit_uuid
      view_uuid
      title
      RoegistryToRoegistryEntries {
        nextToken
        startedAt
      }
      RoegistryToRoegistryEntryPledges {
        nextToken
        startedAt
      }
      is_deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listRoegistries = /* GraphQL */ `
  query ListRoegistries(
    $filter: ModelRoegistryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoegistries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        edit_uuid
        view_uuid
        title
        is_deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncRoegistries = /* GraphQL */ `
  query SyncRoegistries(
    $filter: ModelRoegistryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRoegistries(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        edit_uuid
        view_uuid
        title
        is_deleted
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
