import React, { useEffect, useState } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { createRoegistryEntryPledge } from '../graphql/mutations'
import { useParams, useNavigate } from "react-router-dom";
import { getRoegistryByUUID, getRoegistryEntriesByRoegistryId, getRoegistryEntryPledgesByRoegistryId, ORG_TYPE_TO_DESCRIPTION, getAllOrgs, openInNewTab, numericOnly } from "../utils/utils.js"
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import LinkIcon from '@mui/icons-material/Link';
import IconButton from '@mui/material/IconButton';
import TextField from  '@mui/material/TextField';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Loading from '../components/Loading';
import { cyan } from '@mui/material/colors';
import _ from "lodash";

function ViewRoegistry() {
    const getNewModalData = () => {
        return {orgId: null, roegistryEntryId: null, pledgeAmount: 0}
    }

    function timeout(delay) {
        return new Promise( res => setTimeout(res, delay) );
    }

    let params = useParams();
    const navigate = useNavigate();
    const [accordionExpanded, setAccordionExpanded] = useState({});
    const [modalData, setModalData] = useState(getNewModalData());
    const [roegistry, setRoegistry] = useState({});
    const [roegistryEntries, setRoegistryEntries] = useState([]);
    const [roegistryEntryPledges, setRoegistryEntryPledges] = useState([]);
    const [orgs, setOrgs] = useState([]);

    useEffect(() => {
        const fetchRoegistry = async () => {
            const roegistry = await getRoegistryByUUID(params.uuid, false);
            if (roegistry) {
                const roegistryEntries = await getRoegistryEntriesByRoegistryId(roegistry.id);
                const roegistryEntryPledges = await getRoegistryEntryPledgesByRoegistryId(roegistry.id);
                setRoegistryEntryPledges(roegistryEntryPledges);
                setRoegistryEntries(roegistryEntries);
                setRoegistry(roegistry);
            } else {
                navigate('/');
            }
        }
        fetchRoegistry();
    }, [roegistry.id])

    useEffect(() => {
        const fetchOrgs = async() => {
            const orgs = await getAllOrgs();
            setOrgs(orgs);
        }
        fetchOrgs();
    }, []);

    const handleOpen = (orgId, roegistryEntryId) => {
        setModalData({
            orgId: orgId,
            roegistryEntryId: roegistryEntryId,
            pledgeAmount: 0,
        })
    }

    const handleClose = () => {
        setModalData(getNewModalData());
    }

    const handleClickDonate = async () => {
        try {
            const data = {
                pledge_amount: modalData.pledgeAmount || 0,
                roegistry_entry_id: modalData.roegistryEntryId,
                roegistry_id: roegistry.id, 
                is_deleted: false,
            };
            const resp = await API.graphql(graphqlOperation(createRoegistryEntryPledge, {input: data}));
            const roegistryEntryPledge = resp.data.createRoegistryEntryPledge;
            setRoegistryEntryPledges([
                ...roegistryEntryPledges,
                roegistryEntryPledge,
            ]);
        } catch (err) { console.log('error adding roegistry entry pledge', err)}
        setModalData(getNewModalData());
        await timeout(300);
        openInNewTab(modalOrg.donateLink);
    }

    const handleAccordionChange = (type) => (event, isExpanded) => {
        setAccordionExpanded({...accordionExpanded, [type]: isExpanded});
      };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };

    const orgsById = _.keyBy(orgs, (org) => org.id);
    const modalOrg = orgsById[modalData.orgId];
    const roegistryEntriesByOrgType = _.groupBy(roegistryEntries, (re) => orgsById[re.organization_id].type);
    const pledgesByRoegistryEntry = _.groupBy(roegistryEntryPledges, (rep) => rep.roegistry_entry_id);
    const pledgeSumByRoegistryEntry = _.mapValues(pledgesByRoegistryEntry, (pledges) => _.sumBy(pledges, 'pledge_amount'));

    const modalComponent = (
            <Modal
            open={Boolean(modalData.orgId && modalOrg)}
            onClose={handleClose}
            >
                <Box sx={modalStyle}>
                    <Stack spacing={3}>
                        {modalOrg && 
                        <Typography fontWeight="bold">
                            {modalOrg.name} <IconButton onClick={()=>openInNewTab(modalOrg.link)}><LinkIcon/></IconButton>
                        </Typography>
                        }
                        <Typography gutterBottom>All contribution amounts are self-reported and donations are made directly with the organization.</Typography>
                        <Typography gutterBottom>By clicking "confirm", your intended contribution amount will be reflected on this Roegistry page and you will be redirected to the organization's site.</Typography>
                        <Typography gutterBottom>Click the link icon above to visit the organization's homepage and learn more.</Typography>
                        <Box>
                            <TextField
                            id="pledge-amount"
                            label="Contribution Amount"
                            variant="outlined"
                            type="number"
                            onKeyPress={numericOnly}
                            onChange={(e) => setModalData({...modalData, pledgeAmount: e.target.value})}
                            defaultValue={modalData.pledgeAmount}
                            />
                        </Box>
                        <Box>
                            <Button
                            variant="outlined"
                            color="secondary"
                            onClick={handleClickDonate}
                            >
                            Confirm
                            </Button>
                        </Box>
                    </Stack>
                </Box>
            </Modal>
    );

    const noEntriesComponent = (
        <Paper variant="string" sx= { {mx: "10%", my: 2.5}}>
            <Typography align="center" sx={{fontSize:24}} gutterBottom>
                Oops! It looks like this Roegistry hasn't been set up yet.
            </Typography>
        </Paper>
    )

    return (
        <>
            {modalData.orgId && modalOrg && modalComponent}
            {_.isEmpty(roegistry) ? 
            <Loading/> :
            <Stack >
                <Paper variant="string" sx={ {backgroundColor: 'secondary.main', py: 4} }>
                    <Stack justifyContent="center" alignItems="center">
                        <Typography  sx={{my: 3, color: 'white'}} align="justify" gutterBottom >
                            Not familiar with Roegistry? Don't worry! It works like any other registry, except we replace material gifts with organizations in need of donations. Follow these 3 simple steps: 
                        </Typography>
                        <Box>
                            <Typography sx={{color: 'white'}} align="justify" gutterBottom>
                                1. Select a cause and organization.
                            </Typography>
                            <Typography sx={{color: 'white'}} align="justify" gutterBottom>
                                2. Enter the amount you wish to contribute.
                            </Typography>
                            <Typography sx={{color: 'white'}} align="justify" gutterBottom>
                                3. Confirm and donate!
                            </Typography>
                        </Box>
                    </Stack>
                </Paper>
                {roegistryEntries.length === 0 ? noEntriesComponent : 
                <Paper variant="string" sx= { {mx: "10%", my: 2.5}}>
                    <Typography align="center" sx={{fontSize:24, mb: 3}} gutterBottom>
                        {roegistry.title || "Here's what's available on this Roegistry..."}
                    </Typography>
                    {
                        Object.entries(ORG_TYPE_TO_DESCRIPTION).map(([type, description]) => {
                            const entriesForType = roegistryEntriesByOrgType[type];

                            if (!entriesForType) {
                                return null;
                            }

                            return (
                            <Accordion 
                            key={type}
                            disableGutters
                            expanded={accordionExpanded[type]} 
                            onChange={handleAccordionChange(type)}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                    id={type}
                                >
                                    <Typography variant="h5" align="justify">
                                        I want to {description}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container rowSpacing={4}>
                                        {
                                            entriesForType.map((re) => {
                                                const org = orgsById[re.organization_id];
                                                const pledgeSum = pledgeSumByRoegistryEntry[re.id] || 0;
                                                const sumStr = `Amount Raised: $${pledgeSum}`
                                                const goalStr = re.goal && re.goal > 0 ? ` of $${re.goal} Goal` : '';
                                                return (
                                                    <Grid item md={12} lg={6} key={org.id} >
                                                        <Grid container alignItems="center">
                                                            <Grid item xs={8} key={`desc-${org.id}`}>
                                                                <Typography align="left" fontWeight="bold">
                                                                    {org.name}
                                                                </Typography>
                                                                <Typography align="justify" gutterBottom>
                                                                    {org.description}
                                                                </Typography>
                                                                <Typography align="justify" color={cyan[700]}>
                                                                    {sumStr}{goalStr}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={4} key={`buttons-${org.id}`} sx={{pl:4}}>
                                                                <Button variant="outlined" color="secondary" onClick={() => handleOpen(org.id, re.id)}>Select</Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })
                                        }

                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                            )})}
                </Paper>
                }
            </Stack>
            }
        </>
    )
}

export default ViewRoegistry;