import React from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom";
import {Outlet} from "react-router-dom";
import { roegistryTheme } from '../utils/theme.js';
import { handleCreateRoegistry } from "../utils/utils.js"
import './Layout.css';



const Layout = () => {
  const navigate = useNavigate();
  const navigateHome = () => { navigate('/')};

  const navItems = {
    'Home': navigateHome,
    'Create A Roegistry': () => { handleCreateRoegistry(navigate)},
    'Terms Of Service' : () => { navigate('/terms-of-service')},
  }

  return (
    <div className="Root">
      <AppBar component="nav" color="secondary">
        <Toolbar>
          <Box sx={{ flexGrow: 1}}>
            <Button key={'ROEGISTRY'} onClick={navigateHome}>
              <Typography variant="h5">ROEGISTRY</Typography>
            </Button>
          </Box>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              {Object.entries(navItems).map(([item, func]) => (
              <Button key={item} onClick={func}>
                  {item}
              </Button>
              ))}
          </Box>
        </Toolbar>
      </AppBar>
      <Container sx={{...roegistryTheme.mixins.toolbar}}/>
      <Outlet />
    </div>
  );
};

export default Layout;
