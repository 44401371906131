import { useParams, useNavigate } from "react-router-dom";
import { API, graphqlOperation } from 'aws-amplify'
import { getRoegistryByUUID, getRoegistryEntriesByRoegistryId, ORG_TYPE_TO_DESCRIPTION, getAllOrgs, openInNewTab, numericOnly } from "../utils/utils.js"
import React, { useEffect, useState } from 'react'
import { updateRoegistry, createRoegistryEntry, updateRoegistryEntry } from '../graphql/mutations'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LinkIcon from '@mui/icons-material/Link';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Fade from '@mui/material/Fade';
import TextField from  '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Loading from '../components/Loading'
import { useDebouncedCallback } from 'use-debounce';
import { cyan, pink } from '@mui/material/colors';
import _ from "lodash";


function EditRoegistry() {
    let params = useParams();
    const navigate = useNavigate();
    const [accordionExpanded, setAccordionExpanded] = useState({});
    const [roegistry, setRoegistry] = useState({});
    const [roegistryTitle, setRoegistryTitle] = useState('');
    const [savedVisibility, setSavedVisibility] = useState(false);
    const [roegistryEntries, setRoegistryEntries] = useState([]);
    const [orgsByType, setOrgsByType] = useState({});
    const [pledgeGoals, setPledgeGoals] = useState({});

    useEffect(() => {
        const fetchRoegistry = async () => {
            const roegistry = await getRoegistryByUUID(params.uuid);
            if (roegistry) {
                setRoegistryTitle(roegistry.title);
                const roegistryEntries = await getRoegistryEntriesByRoegistryId(roegistry.id);
                setRoegistryEntries(roegistryEntries);
                setRoegistry(roegistry);
            } else {
                navigate('/');
            }
        }
        fetchRoegistry();
    }, [roegistry.id])

    useEffect(() => {
        const fetchOrgs = async() => {
            const orgs = await getAllOrgs();
            const orgsByType = _.groupBy(orgs, (org) => org.type);
            setOrgsByType(orgsByType);
        }
        fetchOrgs();
    }, []);

    const handleTitleChange = useDebouncedCallback(async (e) => {
        const { value } = e.target;
        setRoegistryTitle(value);
    }, 250);

    useEffect(() => {
        const handleTitleSave = async () => {
            try {
                if (_.isEmpty(roegistry) || roegistryTitle === roegistry.title)  {
                    return;
                }
                const data = _.pick(roegistry, ['id', '_version']);
                data.title = roegistryTitle;
                console.log('updating roegistry with data:', data);
                const resp = await API.graphql(graphqlOperation(updateRoegistry, {input: data}));
                const updatedRoegistry = resp.data.updateRoegistry;
                setRoegistry(updatedRoegistry);
                setSavedVisibility(true);
            } catch (err) { console.log('error updating roegistry', err)}
        };
        handleTitleSave();
    }, [roegistryTitle]);
    

    const handleAddRoegistryEntry = async (org) => {
        try { 
            const data = {
                roegistry_id: roegistry.id,
                organization_id: org.id,
                is_deleted: false,
            };
            if (pledgeGoals[org.id]) {
                data.goal = Number(pledgeGoals[org.id]);
            }
            const resp = await API.graphql(graphqlOperation(createRoegistryEntry, {input: data }));
            const roegistryEntry = resp.data.createRoegistryEntry;
            setRoegistryEntries([
                ...roegistryEntries,
                roegistryEntry,
            ]);
        } catch (err) { console.log('error adding roegistry entry', err)}
    };

    const handleDeleteRoegistryEntry = async (roegistryEntry) => {
        try {
            const data = _.pick(roegistryEntry, ['id', '_version'])
            data['is_deleted'] = true;
            await API.graphql(graphqlOperation(updateRoegistryEntry, {input : data}));
            setRoegistryEntries(roegistryEntries.filter((re) => re.id !== roegistryEntry.id));
            setPledgeGoals(_.omit(pledgeGoals, roegistryEntry.organization_id));
        } catch (err) { console.log('error deleting roegistry etnry', err)}
    }

    const handleDeleteRoegistry = async () => {
        try {
            const data = _.pick(roegistry, ['id', '_version'])
            data['is_deleted'] = true;
            await API.graphql(graphqlOperation(updateRoegistry, {input : data}));
            navigate('/');
        } catch (err) { console.log('error deleting roegistry etnry', err)}

    }

    const getShareLink = (editLink) => {
        return editLink.replace('edit', 'view').replace(roegistry.edit_uuid, roegistry.view_uuid);
    };

    const writeToClipboard = (text) => {
        navigator.clipboard.writeText(text);
    }

    const handleAccordionChange = (type) => (event, isExpanded) => {
        setAccordionExpanded({...accordionExpanded, [type]: isExpanded});
      };
    

    const editLink = window.location.href;
    const shareLink = getShareLink(editLink);
    const roegistryEntriesByOrgId = _.keyBy(roegistryEntries, (re) => re.organization_id)

    return (
        (_.isEmpty(roegistry) ?
        <Loading/>:
        <Stack>
            <Paper variant="string" sx= { {mx: "10%", my: 2.5}}>
                <Grid 
                container 
                rowSpacing={3} 
                columnSpacing={10}>
                    <Grid item xs={12} key="get-started">
                        <Typography align="center" sx={{fontSize:24}}>
                            LET'S GET STARTED
                        </Typography>
                    </Grid>
                    <Grid item xs={12} key="edit-link-info">
                        <Stack>
                            <Typography  align="center" gutterBottom>
                                Roegistry assigns you a link to edit your Roegistry page. The current URL in your browser is your link to edit your personal Roegistry.
                            </Typography>
                            <Typography  align="center" gutterBottom>
                                Save this link for future use. Keep it secure, anyone with this link can edit this Roegistry.
                                <Tooltip title="We recognize the need to help protect your privacy in these circumstances. To limit what data we collect, we don't ask you to sign up with an account with us. Instead, we use links.">
                                    <IconButton><InfoIcon/></IconButton>
                                </Tooltip>
                            </Typography>
                            <Box textAlign="center">
                                <Button variant="outlined" color="secondary" onClick={() => writeToClipboard(editLink)}>Copy Your Link To Clipboard</Button>
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
                <Divider sx={{py:2}}/>
            </Paper>
            <Paper variant="string" sx= { {mx: "10%", my: 2.5}}>
                <Stack justifyContent="center" alignItems="center">
                    <Typography align="center" sx={{fontSize:24}} gutterBottom>
                        NAME YOUR ROEGISTRY (OPTIONAL)
                    </Typography>
                    <Typography align="center" variant="subtitle1" gutterBottom>
                        For example, "Jane's Roegistry"
                    </Typography>
                        <TextField 
                        color="secondary" 
                        id="roegistry-title" 
                        label="Your Roegistry's Name" 
                        variant="outlined"
                        inputProps={{maxLength: 50}}
                        onChange={handleTitleChange} defaultValue={roegistryTitle} 
                        sx={{width: "50%"}}/>
                        <Fade
                            in={savedVisibility}
                            timeout={500}
                            addEndListener={() => {
                                setTimeout(() => {
                                    setSavedVisibility(false);
                                }, 500)
                            }}
                        >
                            <Icon
                            sx={{color: pink[700], mx: 2, my:2}}
                            >
                                <CheckCircleIcon/>
                            </Icon>
                        </Fade>
                </Stack>
                <Divider sx={{py:2}}/>
            </Paper>
            <Paper variant="string" sx= { {mx: "10%", my: 2.5}}>
                <Typography align="center" sx={{fontSize:24, mb: 3}} gutterBottom>
                    CHOOSE YOUR ORGANIZATIONS AND GOALS
                </Typography>
                {
                    Object.entries(ORG_TYPE_TO_DESCRIPTION).map(([type, description]) => (
                        <Accordion 
                        key={type}
                        disableGutters
                        expanded={accordionExpanded[type]} 
                        onChange={handleAccordionChange(type)}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                id={type}
                            >
                                <Typography variant="h5" align="justify">
                                    I want to {description}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container rowSpacing={4}>
                                    {
                                        orgsByType[type] && orgsByType[type].map((org) => {
                                            const roegistryEntry = roegistryEntriesByOrgId[org.id]
                                            return (
                                                <Grid item md={12} lg={6} key={org.id} >
                                                    <Grid container alignItems="center">
                                                        <Grid item xs={8} key={`desc-${org.id}`}>
                                                            <Typography align="left" fontWeight="bold">
                                                                {org.name} <IconButton onClick={()=>openInNewTab(org.link)}><LinkIcon/></IconButton>
                                                            </Typography>
                                                            <Typography align="justify">
                                                                {org.description}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={4} key={`buttons-${org.id}`}>
                                                            <Stack direction="row">
                                                                {roegistryEntry ? 
                                                                (
                                                                    <>
                                                                        <IconButton 
                                                                        sx={{color: pink[700]}}
                                                                        onClick={()=>handleDeleteRoegistryEntry(roegistryEntry)} 
                                                                        >
                                                                            <CheckCircleIcon/>
                                                                        </IconButton>
                                                                        {roegistryEntry.goal ? 
                                                                        <Typography
                                                                        sx={{my: 2, mx: 1}}
                                                                        >
                                                                            Goal: ${roegistryEntry.goal}
                                                                        </Typography> :
                                                                        null
                                                                        }
                                                                    </>
                                                                ):
                                                                (
                                                                    <>
                                                                        <IconButton 
                                                                        sx={{color: cyan[700]}}
                                                                        onClick={()=>handleAddRoegistryEntry(org)} 
                                                                        >
                                                                            <AddCircleOutlinedIcon/>
                                                                        </IconButton>
                                                                        <TextField 
                                                                            id="fundraising-goal" 
                                                                            label="Goal" 
                                                                            variant="outlined" 
                                                                            type="number"
                                                                            onKeyPress={numericOnly}
                                                                            sx={{width:120}}
                                                                            size="small"
                                                                            onChange={(e) => setPledgeGoals({...pledgeGoals, [org.id]: e.target.value})}
                                                                        />
                                                                    </>
                                                                )
                                                                }
                                                            </Stack>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    ))
                }
                <Divider sx={{py:2}}/>
            </Paper>
            <Paper variant="string" sx= { {mx: "10%", my: 2.5}}>
                <Stack container spacing={3}>
                    <Typography align="center" sx={{fontSize:24}}>
                        ALL DONE?
                    </Typography>
                    <Typography  align="center" gutterBottom>
                        When your Roegistry is ready, save your shareable link and pass it along to anyone you'd like to invite to view your Roegistry. Anyone with this link will be able to view your Roegistry. Click below to get your shareable link.
                    </Typography>
                    <Box textAlign="center">
                        <Button variant="outlined" color="secondary" onClick={() => writeToClipboard(shareLink)}>Copy Share Link To Clipboard</Button>
                    </Box>
                    <Typography align="center" gutterBottom>
                        If you'd like to preview your Roegistry before sharing it, click the preview button, which will take you to that same shareable link in a new tab.
                    </Typography>
                    <Box textAlign="center">
                        <Button variant="outlined" color="secondary" onClick={() => openInNewTab(shareLink)}>Preview Your Roegistry</Button>
                    </Box>
                </Stack>
            </Paper>
            <Paper variant="string" sx={ {backgroundColor: 'secondary.main', py: 4} }>
                <Stack container spacing={3}>
                    <Typography  align="center" gutterBottom sx={{color: 'white'}}>
                        Want to delete your Roegistry? Click below. You'll no longer have access to this Roegistry.
                    </Typography>
                    <Box textAlign="center">
                        <Button variant="outlined" color="primary" onClick={handleDeleteRoegistry}>Delete Your Roegistry</Button>
                    </Box>
                </Stack>
            </Paper>
        </Stack>
        )
    );
}

export default EditRoegistry;
