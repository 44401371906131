/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createRoegistryEntryPledge = /* GraphQL */ `
  mutation CreateRoegistryEntryPledge(
    $input: CreateRoegistryEntryPledgeInput!
    $condition: ModelRoegistryEntryPledgeConditionInput
  ) {
    createRoegistryEntryPledge(input: $input, condition: $condition) {
      id
      pledge_amount
      roegistry_entry_id
      roegistry_id
      is_deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateRoegistryEntryPledge = /* GraphQL */ `
  mutation UpdateRoegistryEntryPledge(
    $input: UpdateRoegistryEntryPledgeInput!
    $condition: ModelRoegistryEntryPledgeConditionInput
  ) {
    updateRoegistryEntryPledge(input: $input, condition: $condition) {
      id
      pledge_amount
      roegistry_entry_id
      roegistry_id
      is_deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteRoegistryEntryPledge = /* GraphQL */ `
  mutation DeleteRoegistryEntryPledge(
    $input: DeleteRoegistryEntryPledgeInput!
    $condition: ModelRoegistryEntryPledgeConditionInput
  ) {
    deleteRoegistryEntryPledge(input: $input, condition: $condition) {
      id
      pledge_amount
      roegistry_entry_id
      roegistry_id
      is_deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createRoegistryEntry = /* GraphQL */ `
  mutation CreateRoegistryEntry(
    $input: CreateRoegistryEntryInput!
    $condition: ModelRoegistryEntryConditionInput
  ) {
    createRoegistryEntry(input: $input, condition: $condition) {
      id
      roegistry_id
      organization_id
      goal
      is_deleted
      RoegistryEntryPledges {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateRoegistryEntry = /* GraphQL */ `
  mutation UpdateRoegistryEntry(
    $input: UpdateRoegistryEntryInput!
    $condition: ModelRoegistryEntryConditionInput
  ) {
    updateRoegistryEntry(input: $input, condition: $condition) {
      id
      roegistry_id
      organization_id
      goal
      is_deleted
      RoegistryEntryPledges {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteRoegistryEntry = /* GraphQL */ `
  mutation DeleteRoegistryEntry(
    $input: DeleteRoegistryEntryInput!
    $condition: ModelRoegistryEntryConditionInput
  ) {
    deleteRoegistryEntry(input: $input, condition: $condition) {
      id
      roegistry_id
      organization_id
      goal
      is_deleted
      RoegistryEntryPledges {
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization(
    $input: CreateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    createOrganization(input: $input, condition: $condition) {
      id
      type
      name
      description
      link
      OrganizationToRoegistryEntry {
        nextToken
        startedAt
      }
      is_deleted
      donateLink
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization(
    $input: UpdateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    updateOrganization(input: $input, condition: $condition) {
      id
      type
      name
      description
      link
      OrganizationToRoegistryEntry {
        nextToken
        startedAt
      }
      is_deleted
      donateLink
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteOrganization = /* GraphQL */ `
  mutation DeleteOrganization(
    $input: DeleteOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    deleteOrganization(input: $input, condition: $condition) {
      id
      type
      name
      description
      link
      OrganizationToRoegistryEntry {
        nextToken
        startedAt
      }
      is_deleted
      donateLink
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createRoegistry = /* GraphQL */ `
  mutation CreateRoegistry(
    $input: CreateRoegistryInput!
    $condition: ModelRoegistryConditionInput
  ) {
    createRoegistry(input: $input, condition: $condition) {
      id
      edit_uuid
      view_uuid
      title
      RoegistryToRoegistryEntries {
        nextToken
        startedAt
      }
      RoegistryToRoegistryEntryPledges {
        nextToken
        startedAt
      }
      is_deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateRoegistry = /* GraphQL */ `
  mutation UpdateRoegistry(
    $input: UpdateRoegistryInput!
    $condition: ModelRoegistryConditionInput
  ) {
    updateRoegistry(input: $input, condition: $condition) {
      id
      edit_uuid
      view_uuid
      title
      RoegistryToRoegistryEntries {
        nextToken
        startedAt
      }
      RoegistryToRoegistryEntryPledges {
        nextToken
        startedAt
      }
      is_deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteRoegistry = /* GraphQL */ `
  mutation DeleteRoegistry(
    $input: DeleteRoegistryInput!
    $condition: ModelRoegistryConditionInput
  ) {
    deleteRoegistry(input: $input, condition: $condition) {
      id
      edit_uuid
      view_uuid
      title
      RoegistryToRoegistryEntries {
        nextToken
        startedAt
      }
      RoegistryToRoegistryEntryPledges {
        nextToken
        startedAt
      }
      is_deleted
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
