import React from "react";
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import EditRoegistry from "./pages/EditRoegistry";
import ViewRoegistry from "./pages/ViewRoegistry";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import TermsOfService from "./pages/TermsOfService";
import { ThemeProvider } from  '@mui/material/';
import { roegistryTheme } from './utils/theme.js';

export default function App() {
  return (
    <ThemeProvider theme={roegistryTheme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="/edit/roegistry/:uuid" element={<EditRoegistry />} />
            <Route path="/view/roegistry/:uuid" element={<ViewRoegistry />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="*" element={<Home />} />
          </Route>
        </Routes>
      </BrowserRouter>
     </ThemeProvider>
  );
}

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);