import React from 'react'
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress'
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

function Loading() {
    return (
        <Stack>
            <Paper variant="string" sx= { {my: 20} }>
                <Box textAlign="center">
                    <CircularProgress color="secondary"/>
                </Box>
            </Paper>
        </Stack>
    );
}

export default Loading;